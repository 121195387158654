import { LqdDragAndDropIcon, LqdIconButton, LqdTooltip, LqdTrashIcon, LqdTypography } from "@/liquid-components/src";
import { formatTrustpadDashboardCardValue } from "@analysis/utils/formatTrustpadDashboardCardValue";
import { truncateString } from "@common/utils/truncateString";
import Draggable from "gsap/Draggable";
import { Box, Stack, SxProps } from "@mui/material";
import { SimulatorVariable } from "../../../../types/SimulatorVariable";
import { toastCalled } from "@common/commonSlice";
import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ToastNotification } from "@common/types/ToastNotification";
import { setDashboardCards } from "@simulatorBuilder/simulatorBuilderSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../store";

type SimulatorResultFilledCard = {
  handleDeleteClick: (index: number) => void;
  index: number;
  selectedVariable: { content: SimulatorVariable | null; id: number };
  wrapper: SxProps;
};

gsap.registerPlugin(Draggable);

export default function SimulatorResultFilledCard(props: SimulatorResultFilledCard) {
  const { handleDeleteClick, index, selectedVariable, wrapper } = props;

  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);

  const cards = simulatorData?.dashboard.cards;

  const dispatch = useAppDispatch();

  const cardRef = useRef<HTMLDivElement>(null);

  const sendErrorNotification = () => {
    const notification: ToastNotification = {
      message: "Não existem cards para serem movidos.",
      type: "error",
    };

    dispatch(toastCalled(notification));
  };

  const handleReorder = (oldIndex: number, newIndex: number) => {
    if (!cards?.length) {
      sendErrorNotification();

      return;
    }

    const copyArray = [...cards];

    if (oldIndex === newIndex) return;

    const movedItem = copyArray.find((item) => item.index === oldIndex);

    if (!movedItem) {
      const notification: ToastNotification = {
        message: "Item a ser movido não encontrado.",
        type: "error",
      };

      dispatch(toastCalled(notification));
      return;
    }

    // Remove from old position
    copyArray.splice(oldIndex - 1, 1);

    // Insert at new position
    copyArray.splice(newIndex - 1, 0, movedItem);

    // Update indexs
    const reorderedArray = copyArray.map((item, index) => ({
      ...item,
      index: index + 1,
    }));

    dispatch(setDashboardCards(reorderedArray));
  };

  useEffect(() => {
    const el = cardRef.current;
    const cardHeight = el?.offsetHeight || 0;
    const cardWidth = el?.offsetWidth || 0;

    // Get grid dimensions
    const gridColumns = 3;
    const itemsPerRow = gridColumns;

    Draggable.create(el, {
      onDrag: function () {
        const elements = document.querySelectorAll('[data-draggable-card="true"]');
        const dragBounds = el!.getBoundingClientRect();
        const dragCenterX = dragBounds.left + dragBounds.width / 3;
        const dragCenterY = dragBounds.top + dragBounds.height / 3;

        elements.forEach((item) => {
          if (item === el) return;

          const bounds = item.getBoundingClientRect();
          const centerX = bounds.left + bounds.width / 4;
          const centerY = bounds.top + bounds.height / 4;

          // Calculate displacement for both X and Y
          if (Math.abs(dragCenterX - centerX) < bounds.width && Math.abs(dragCenterY - centerY) < bounds.height) {
            const moveX = dragCenterX < centerX ? -cardWidth / 4 : cardWidth / 4;
            const moveY = dragCenterY < centerY ? -cardHeight / 4 : cardHeight / 4;

            gsap.to(item, {
              duration: 0.3,
              ease: "power2.out",
              x: moveX,
              y: moveY,
            });
          } else {
            gsap.to(item, {
              duration: 0.3,
              ease: "power2.out",
              x: 0,
              y: 0,
            });
          }
        });
      },

      onDragEnd: function () {
        if (!cards?.length) {
          sendErrorNotification();
          return;
        }

        const elements = document.querySelectorAll('[data-draggable-card="true"]');

        // Calculate new position based on grid
        const newRow = Math.round(this.y / cardHeight);
        const newCol = Math.round(this.x / cardWidth);

        // Convert grid position to linear index
        const newIndex = newRow * itemsPerRow + newCol + 1;
        const oldIndex = selectedVariable.id;

        // Ensure index is within bounds
        const boundedIndex = Math.max(1, Math.min(newIndex, cards.length));

        handleReorder(oldIndex, boundedIndex);

        // Reset positions
        elements.forEach((item) => {
          gsap.to(item, {
            duration: 0.3,
            ease: "power2.out",
            x: 0,
            y: 0,
          });
        });

        gsap.to(el, {
          duration: 0.5,
          ease: "power2.out",
          x: 0,
          y: 0,
        });
      },

      onPress() {
        gsap.to(el, {
          borderLeft: "3px solid gray",
          duration: 0.2,
          rotation: -2.5,
          x: 0,
        });
      },

      onRelease() {
        gsap.to(el, {
          borderLeft: "1px solid rgba(212, 215, 220, 1)",
          duration: 0.2,
          rotation: 0,
          x: 0,
        });
      },
      type: "x,y",
    });
  }, [index, handleReorder]);

  return (
    <Stack
      data-draggable-card="true"
      direction="column"
      ref={cardRef}
      spacing="auto"
      sx={{ ...wrapper, background: "rgba(249, 249, 250, 1)" }}
    >
      <Box sx={{ alignItems: "center", display: "inline-flex", justifyContent: "space-between" }}>
        <LqdDragAndDropIcon size={20} />

        <LqdIconButton onClick={() => handleDeleteClick(index)} type="ghostIcon">
          <LqdTrashIcon color="rgba(127, 135, 152, 1)" size={20} />
        </LqdIconButton>
      </Box>
      <Box>
        <Stack direction="column" gap={0.5} sx={{ width: "fit-content" }}>
          <LqdTooltip
            hidetooltip={selectedVariable!.content!.label.length > 20 ? "false" : "true"}
            placement="right"
            titlebody={selectedVariable!.content!.label}
          >
            <LqdTypography
              color="rgba(75, 75, 75, 1)"
              sx={{ overflowWrap: "break-word", width: "fit-content" }}
              textstyle="c1Caption"
            >
              {truncateString(selectedVariable!.content!.label, 20)}
            </LqdTypography>
          </LqdTooltip>
          <LqdTooltip
            hidetooltip={selectedVariable!.content!.name.length > 20 ? "false" : "true"}
            placement="right"
            titlebody={selectedVariable!.content!.name}
          >
            <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ overflowWrap: "break-word" }} textstyle="c1Caption">
              {truncateString(selectedVariable!.content!.name, 20)}
            </LqdTypography>
          </LqdTooltip>
        </Stack>
        <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="p1Paragraph">
          {selectedVariable.content?.value
            ? formatTrustpadDashboardCardValue(
                selectedVariable.content.value,
                selectedVariable.content.type!,
                selectedVariable.content.label
              )
            : "Valor não definido (dinâmico)"}
        </LqdTypography>
      </Box>
    </Stack>
  );
}
