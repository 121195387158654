import { IDRC_KEY } from "@common/utils/apiUtils";
import { onChooseIdrc } from "@signature/signatureSlice";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import SignatureLoading from "./SignatureLoading";

export default function IdrcSigning() {
  const dispatch = useAppDispatch();

  const { peerData } = useAppSelector((state) => state.signature);
  const { analysis_id, pass_id, tenant_code, token_id } = peerData;

  useEffect(() => {
    const idrcSign = async () => {
      const data = { analysis_id, pass_id, tenant_code, token_id };
      const idrcCode = await dispatch(onChooseIdrc(data)).unwrap();
      const idrcUrl = `https://signer.hml.dcs.registrocivil.org.br?code=${idrcCode}&key=${IDRC_KEY}`;
      window.location.href = idrcUrl;
    };

    idrcSign();
  }, []);

  return <SignatureLoading />;
}
