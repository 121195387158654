import { Box } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { toastCalled } from "../../../common/commonSlice";
import { ToastNotification } from "../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import { onLoadTenants, onUpdateTenant } from "../../adminSliceThunks";
import { TenantCreateRaw } from "../../types/TenantCreateRaw";
import { TenantResponse } from "../../types/TenantResponse";
import TenantFormStep1 from "./TenantCreationSteps/TenantFormStep1";
import TenantFormStep2 from "./TenantCreationSteps/TenantFormStep2";
import TenantFormStep3 from "./TenantCreationSteps/TenantFormStep3";
import TenantFormEditSummary from "./TenantFormEditSummary";

type EditTenantFormProps = {
  checkedProviders: Array<string>;
  checkedSegments: Array<string>;
  onCloseDialog: () => void;
  setCheckedProviders: (checkedProviders: Array<string>) => void;
  setCheckedSegments: (checkedSegments: Array<string>) => void;
  tenant: TenantCreateRaw | TenantResponse;
};

export default function EditTenantForm(props: EditTenantFormProps) {
  const { checkedProviders, checkedSegments, onCloseDialog, setCheckedProviders, setCheckedSegments, tenant } = props;

  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { editTenantModalStep, tenantToEdit } = useAppSelector((state) => state.admin);

  const [loading, setLoading] = useState(false);

  const handleEditTenant = async () => {
    setLoading(true);
    if (!tenantToEdit) {
      throw new Error("Could not get tenantToEdit");
    }

    try {
      const updatedTenant = {
        contact: {
          email: tenantToEdit?.contact.email || "",
          name: tenantToEdit?.contact.name || "",
          phone: tenantToEdit?.contact.phone || "",
        },
        is_blocked: tenantToEdit?.isBlocked || false,
        providers: checkedProviders,
        segments: checkedSegments,
      };

      await dispatch(onUpdateTenant({ tenantCode: tenantToEdit?.code, tenantInfo: updatedTenant }));

      const notification: ToastNotification = {
        message: "Empresa atualizada com sucesso.",
        type: "success",
      };

      await dispatch(onLoadTenants());

      dispatch(toastCalled(notification));
      onCloseDialog();
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const renderCurrentStep = () => {
    switch (editTenantModalStep) {
      case 1:
        return <TenantFormStep1 />;
      case 2:
        return <TenantFormStep2 checkedSegments={checkedSegments} setCheckedSegments={setCheckedSegments} />;
      case 3:
        return <TenantFormStep3 checkedProviders={checkedProviders} setCheckedProviders={setCheckedProviders} />;
      case 4:
        return (
          <TenantFormEditSummary
            checkedProviders={checkedProviders}
            checkedSegments={checkedSegments}
            handleSubmit={handleEditTenant}
            loading={loading}
            tenant={tenant}
          />
        );
    }
  };

  return tenantToEdit ? (
    <Box sx={{ alignItems: "center", display: "flex", height: "80vh", px: "15%" }}>{renderCurrentStep()}</Box>
  ) : null;
}
