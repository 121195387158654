import { Fragment } from "react";
import { useAppSelector } from "../../../../../../store";
import { simulatorVariablesToResultCard } from "../../../../utils/transformSimulatorTypes";
import { useSimulatorResultHandler } from "../../../../utils/useSimulatorResultHandler";
import SimulatorEmptyCard from "../SimulatorEmptyCard";
import SimulatorResultFormsContainer from "../SimulatorResultFormsContainer";
import SimulatorResultFilledCard from "./SimulatorResultFilledCard";
import { Box } from "@mui/material";

const INITIAL_CONTENT_LIST = [{ content: null, id: 1 }];

export default function SimulatorResultCardsForm() {
  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);
  const simulator = simulatorData!;

  const dashboardCards =
    simulator?.dashboard.cards.length > 0
      ? simulator.dashboard.cards.map((card) => ({
          content: simulatorVariablesToResultCard(card, card.index),
          id: card.index,
        }))
      : INITIAL_CONTENT_LIST;

  const { addNewComponent, removeComponent, updateComponent } = useSimulatorResultHandler({
    type: "cards",
  });

  return (
    <SimulatorResultFormsContainer
      addNewComponent={addNewComponent}
      subtitle="Defina variáveis para exibir como cards no Dashboard"
      title="Cards"
    >
      <Box
        sx={{
          display: "grid",
          gap: 2,
          gridTemplateColumns: "repeat(3, 1fr)", // 3 columns
          width: "100%",
        }}
      >
        {dashboardCards.map((card, index) => (
          <Fragment key={`card-${card.id}`}>
            {card.content?.name ? (
              <SimulatorResultFilledCard
                handleDeleteClick={removeComponent}
                index={card.content.index}
                selectedVariable={card}
                wrapper={{
                  border: "1px solid rgba(212, 215, 220, 1)",
                  borderRadius: "12px",
                  height: "140px",
                  padding: "12px 16px",
                  width: "244px",
                }}
              />
            ) : (
              <SimulatorEmptyCard
                handleChange={updateComponent}
                index={card.content?.index || index}
                selectedVariable={card}
                wrapper={{
                  border: "1px solid rgba(212, 215, 220, 1)",
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  height: "140px",
                  justifyContent: "flex-end",
                  padding: "12px 16px",
                  width: "244px",
                }}
              />
            )}
          </Fragment>
        ))}
      </Box>
    </SimulatorResultFormsContainer>
  );
}
