import { post } from "../../common/utils/apiUtils";
import { GenerateIdrcData, IdrcResponse } from "../types/idrc";

export const generateIdrcCode = (data: GenerateIdrcData) => {
  return post<IdrcResponse>(
    "/idrc/generate-idrc-code",
    {
      analysis_id: data.analysis_id,
      pass_id: data.pass_id,
      tenant_code: data.tenant_code,
      token_id: data.token_id,
    },
    { headers: { "peer-token": data.token_id } }
  );
};
