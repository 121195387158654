import { formattedCondition } from "@analysis/utils/formatTrustpadCondition";
import { formattedProviderValue } from "@analysis/utils/formatTrustpadProviderValue";
import { dateFormatter } from "@common/utils/dataFormatter";
import { kpiDictionary } from "@productBuilder/utils/kpiDictionary";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { AnalysisDetailsStep } from "../../../../../types/AnalysisDetails/Analysis/AnalysisDetailsStep";
import PDFTrupstadLineSVG from "./StepSVG/PDFTrupstadLineSVG";
import PDFTrustpadDefaultSVG from "./StepSVG/PDFTrustpadDefaultSVG";

type PDFTrustpadPeerDefaultStepCardProps = {
  lastIndex?: boolean;
  lineColor: string;
  step: AnalysisDetailsStep;
};

const styles = StyleSheet.create({
  conditionValue: {
    alignSelf: "flex-end",
    color: "rgb(127, 135, 152)",
    fontSize: "10px",
  },
  flexColumnContainerJustify: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  flexRowContainerJustify: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  genericFlexColumnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  providerValue: {
    alignSelf: "flex-end",
    color: "rgb(33, 36, 42)",
    fontSize: "24px",
  },
  stepDate: {
    color: "rgb(127, 135, 152)",
    fontSize: "12px",
    paddingBottom: "5px",
  },
  stepDescription: {
    color: "rgb(33, 36, 42)",
    fontSize: "12px",
  },
  trustpadDefaultStepDescriptionContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "500px",
  },
  trustpadDefaultStepGrayContainer: {
    backgroundColor: "rgb(245, 248, 249)",
    borderRadius: "20px",
    margin: "0 auto",
    padding: "24px",
    width: "70%",
  },
  trustpadDefaultStepMainContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "0 auto",
    marginVertical: "20px",
  },
});

export default function PDFTrustpadPeerDefaultStepCard(props: PDFTrustpadPeerDefaultStepCardProps) {
  const { lastIndex, lineColor, step } = props;

  return (
    <View minPresenceAhead={40} style={styles.trustpadDefaultStepMainContainer}>
      <View style={styles.genericFlexColumnContainer}>
        <PDFTrustpadDefaultSVG />
        {!lastIndex ? <PDFTrupstadLineSVG color={lineColor} /> : null}
      </View>
      <View style={styles.trustpadDefaultStepGrayContainer}>
        <View style={styles.flexRowContainerJustify}>
          <View style={styles.trustpadDefaultStepDescriptionContainer}>
            <Text style={styles.stepDate}>{dateFormatter(step.matchAt * 1000).fullDateTextTime}</Text>
            <Text style={styles.stepDescription}>
              {step.kpi === "highlight__has_approved_bank"
                ? step.description
                : kpiDictionary[step.kpi] || step.description}
            </Text>
          </View>
          <View style={styles.flexColumnContainerJustify}>
            <Text style={styles.providerValue}>{formattedProviderValue(step)}</Text>
            <Text style={styles.conditionValue}>{formattedCondition(step)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}
