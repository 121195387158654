import { AnalysisPendencySignChoices } from "@analysis/types/AnalysisDetails/Analysis/AnalysisPendencySignChoices";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { WritableDraft } from "immer";
import { PDFDocumentProxy } from "pdfjs-dist";
import { generateIdrcCode } from "./api/generateIdrcCode";
import { generateLiderSchedule } from "./api/GenerateLiderSchedule";
import { getPDFFromBucket } from "./api/GetPDFFromBucket";
import { getPeerDataByToken } from "./api/GetPeerDataByToken";
import { getPeerPendency } from "./api/getPeerPendecy";
import { performCloudSigning } from "./api/performCloudSigning";
import { performLocalSignature } from "./api/PerformLocalSignature";
import { searchCloudProvidersbyCPF } from "./api/searchCloudProviders";
import { startLocalSignature } from "./api/StartLocalSignature";
import { UpdateMetadata, UpdateMetadataParams } from "./api/UpdateMetadata";
import { CloudService } from "./types/cloudProvidersSession";
import { GenerateIdrcData } from "./types/idrc";
import { GenerateSchedulePost, ScheduleResponse, SignaturePeerData } from "./types/schedule";
import { SignatureViews } from "./types/signatureViews";

interface SignatureState {
  cloudProviders: Array<CloudService>;
  contract: {
    originalDocumentUrl: string;
    portalDocumentUrl: string;
    status: number;
    statusDescription: string;
  };
  cpf: string;
  currentView: SignatureViews;
  documentFile: PDFDocumentProxy | null;
  loading: boolean;
  localSignatureToken: string;
  peerData: SignaturePeerData;
  scheduleData: ScheduleResponse;
  showDocumentOptionsMenu: boolean;
  signatureLinkSentDate: number;
  signPendencies: AnalysisPendencySignChoices;
  token: string;
}

const initialState: SignatureState = {
  cloudProviders: [],
  contract: {
    originalDocumentUrl: "",
    portalDocumentUrl: "",
    status: 1,
    statusDescription: "",
  },
  cpf: "",
  currentView: SignatureViews.DOCUMENT_VIEW,
  documentFile: null,
  loading: true,
  localSignatureToken: "",
  peerData: {
    analysis_id: "",
    birthday: "",
    ddd: "",
    document: "",
    email: "",
    full_name: "",
    object_key: "",
    pass_id: "",
    pendency_id: "",
    phone: "",
    process_id: 0,
    subscriber_id: "",
    tenant_code: "",
    token_id: "",
  },
  scheduleData: {
    data: {
      id: 0,
      schedule_link: "",
    },
    success: false,
    total: 0,
  },
  showDocumentOptionsMenu: false,
  signPendencies: {
    peers: [],
    peers_to_sign: {},
  },
  signatureLinkSentDate: 0,
  token: "",
};

export const onSearchCloudProvidersForCPF = createAsyncThunk(
  "signature/onSearchCloudProvidersForCPF",
  async (data: { cpf: string; peerToken: string }, thunk) => {
    try {
      const session = await searchCloudProvidersbyCPF(data.cpf, data.peerToken);
      const cloudProviders = session.providers.services;
      return cloudProviders;
    } catch (error) {
      return thunk.rejectWithValue(error);
    }
  }
);
export const onChooseIdrc = createAsyncThunk("signature/onChooseIdrc", async (data: GenerateIdrcData, thunk) => {
  try {
    const idrc = await generateIdrcCode(data);
    return idrc.data.code;
  } catch (error) {
    return thunk.rejectWithValue(error);
  }
});
export const onSearchContractUrl = createAsyncThunk("signature/onSearchContractUrl", async (token: string, thunk) => {
  try {
    const url = await getPDFFromBucket(token);
    return url.data;
  } catch (error) {
    return thunk.rejectWithValue(error);
  }
});

export const onFinishingCloudSigning = createAsyncThunk(
  "signature/onFinishingCloudSigning",
  async (data: { peerToken: string; session: string }, thunk) => {
    try {
      const documentSigning = await performCloudSigning(data.session, data.peerToken);
      return documentSigning;
    } catch (error) {
      return thunk.rejectWithValue(error);
    }
  }
);

export const onGetingPeerDataByToken = createAsyncThunk<
  SignaturePeerData,
  string,
  {
    rejectValue: {
      error: string;
      message: string;
      status: number;
    };
  }
>("signature/onGetingPeerDataByToken", async (token: string, { rejectWithValue }) => {
  try {
    const peerData = await getPeerDataByToken(token);
    return peerData.data;
  } catch (error) {
    const errorData = error as AxiosError;
    const customError = {
      error: errorData.message,
      message: errorData.response?.data?.message || "An error occurred",
      status: errorData.response?.status || 500,
    };
    return rejectWithValue(customError);
  }
});

export const onGetingPeerPendency = createAsyncThunk("signature/onGetingPeerPendency", async (token: string, thunk) => {
  try {
    const peerPendency = await getPeerPendency(token);
    return peerPendency.data;
  } catch (error) {
    return thunk.rejectWithValue(error);
  }
});

export const onStartingLocalSignature = createAsyncThunk(
  "signature/onStartingLocalSignature",
  async (token: string, thunk) => {
    try {
      const hasLocal = await startLocalSignature(token);
      return hasLocal;
    } catch (error) {
      return thunk.rejectWithValue(error);
    }
  }
);

export const onPerformingLocalSignature = createAsyncThunk(
  "signature/onPerformingLocalSignature",
  async (data: { peerToken: string; signatureToken: string }, thunk) => {
    try {
      const performSign = await performLocalSignature(data.signatureToken, data.peerToken);
      return performSign;
    } catch (error) {
      return thunk.rejectWithValue(error);
    }
  }
);

export const onScheduling = createAsyncThunk("signature/onScheduling", async (data: GenerateSchedulePost, thunk) => {
  try {
    const schedule = await generateLiderSchedule(data);
    return schedule;
  } catch (error) {
    return thunk.rejectWithValue(error);
  }
});

export const onSendingMetadata = createAsyncThunk(
  "signature/onSendingMetadata",
  async (data: UpdateMetadataParams, thunk) => {
    try {
      const metaDataReponse = await UpdateMetadata(data);
      return metaDataReponse;
    } catch (error) {
      return thunk.rejectWithValue(error);
    }
  }
);

const signatureSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(onSearchCloudProvidersForCPF.fulfilled, (state, action) => {
        const cloudProviders = action.payload;
        state.cloudProviders = [...cloudProviders];
      })
      .addCase(onFinishingCloudSigning.pending, (state) => {
        state.loading = true;
        state.currentView = SignatureViews.LOADING;
      })
      .addCase(onFinishingCloudSigning.fulfilled, (state, action) => {
        const cloudSignSucess = action.payload;
        if (cloudSignSucess) {
          state.currentView = SignatureViews.SIGNATURE_SUCCESS;
        }
        state.loading = false;
      })
      .addCase(onFinishingCloudSigning.rejected, (state) => {
        state.currentView = SignatureViews.SIGNATURE_FAILED;
        state.loading = false;
      })
      .addCase(onGetingPeerDataByToken.rejected, (state, action) => {
        const status = action.payload?.status;
        if (status === 401) {
          state.currentView = SignatureViews.EXPIRED_LINK;
        } else {
          state.currentView = SignatureViews.SOMETHING_WENT_WRONG;
        }
        state.loading = false;
      })
      .addCase(onStartingLocalSignature.fulfilled, (state, action) => {
        const token = action.payload;
        const signatureToken = token.signature_token;

        if (signatureToken !== "") {
          state.localSignatureToken = signatureToken;
        }
      })
      .addCase(onStartingLocalSignature.rejected, (state) => {
        state.currentView = SignatureViews.SIGNATURE_FAILED;
      })
      .addCase(onGetingPeerDataByToken.fulfilled, (state, action) => {
        const peerData = action.payload;
        state.peerData = peerData;
      })
      .addCase(onPerformingLocalSignature.pending, (state) => {
        state.loading = true;
        state.currentView = SignatureViews.LOADING;
      })
      .addCase(onPerformingLocalSignature.fulfilled, (state) => {
        state.loading = false;
        state.currentView = SignatureViews.SIGNATURE_SUCCESS;
      })
      .addCase(onPerformingLocalSignature.rejected, (state) => {
        state.loading = false;
        state.currentView = SignatureViews.SIGNATURE_FAILED;
      })
      .addCase(onSearchContractUrl.fulfilled, (state, action) => {
        const data = action.payload;
        state.contract.originalDocumentUrl = data.raw_file;
        state.contract.portalDocumentUrl = data.portal_file;
        state.contract.status = data.status;
        state.contract.statusDescription = data.description;
      })
      .addCase(onSendingMetadata.fulfilled, (state, action) => {
        const response = action.payload.data;
        if (response.process_id === 0) {
          state.peerData.email = response.email;
        } else {
          state.peerData.process_id = response.process_id;
        }
      })
      .addCase(onScheduling.fulfilled, (state, action) => {
        const response = action.payload.data;
        state.scheduleData = response;
      })
      .addCase(onGetingPeerPendency.fulfilled, (state, action) => {
        const signPendencies = action.payload.choices as AnalysisPendencySignChoices;
        state.signPendencies = signPendencies;
        const signatureLinkSentDate = action.payload.sentLinkTimestamp;
        state.signatureLinkSentDate = signatureLinkSentDate;
      });
  },
  initialState,
  name: "signature",
  reducers: {
    clearSignatureState(state) {
      state.cloudProviders = [];
      state.token = "";
      state.scheduleData = initialState.scheduleData;
      state.peerData = initialState.peerData;
      state.contract = initialState.contract;
      state.cpf = "";
    },
    onChangingView(state, action: PayloadAction<SignatureViews>) {
      state.currentView = action.payload;
    },
    onCloseDocumentOptionsMenuModal(state) {
      state.showDocumentOptionsMenu = false;
    },
    onLoading(state, action) {
      state.loading = action.payload;
    },
    onOpenDocumentOptionsMenuModal(state) {
      state.showDocumentOptionsMenu = true;
    },
    onSavingCpf(state, action: PayloadAction<string>) {
      state.cpf = action.payload;
    },
    onSavingToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    onSettingDocumentFile(state, action: PayloadAction<PDFDocumentProxy>) {
      state.documentFile = action.payload as WritableDraft<PDFDocumentProxy>;
    },
  },
});

export const {
  clearSignatureState,
  onChangingView,
  onCloseDocumentOptionsMenuModal,
  onLoading,
  onOpenDocumentOptionsMenuModal,
  onSavingCpf,
  onSavingToken,
  onSettingDocumentFile,
} = signatureSlice.actions;

export default signatureSlice.reducer;
