import { generateTimestamp } from "@common/utils/timestamp";
import { ProductState } from "@products/productsSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { UsersState } from "@users/usersSlice";
import { ObjectOf } from "../common/types/ObjectOf";
import { updateChoices } from "../uploadDocument/api/UpdateChoices";
import { archiveAnalysis } from "./api/ArchiveAnalysis";
import { getAIMessages } from "./api/GetAIMessages";
import { getAITraining } from "./api/GetAITraining";
import { getAnalysisPluggyBookData } from "./api/GetAnalysisBook";
import { getAnalysisDetails } from "./api/GetAnalysisDetails";
import { getAnalysisPendenciesList } from "./api/GetAnalysisPendenciesList";
import { getProductBankRates } from "./api/GetProductBankRates";
import { reactToMessage } from "./api/ReactToMessage";
import { runOpenedAnalysis } from "./api/RunOpenedAnalysis";
import { sendMessageToAI } from "./api/SendMessageToAI";
import { updateAnalysisDetails } from "./api/UpdateAnalysisDetails";
import { updatePendencies } from "./api/UpdatePendencies";
import { AnalysisDetails } from "./types/AnalysisDetails/Analysis/AnalysisDetails";
import { AnalysisPendency } from "./types/AnalysisDetails/Analysis/AnalysisPendency";
import {
  AnalysisPendencySignChoices,
  PeerToSignPendency,
} from "./types/AnalysisDetails/Analysis/AnalysisPendencySignChoices";
import { AnalysisPendencyUploadChoices } from "./types/AnalysisDetails/Analysis/AnalysisPendencyUploadChoices";
import { PeerDataRaw } from "./types/AnalysisDetails/PeerData/PeerDataRaw";
import { AnalysisDetailsState } from "./types/AnalysisDetailsState";
import { formatRawAnalysisDetails } from "./utils/formatRawAnalysisDetails";
import { formatRawAnalysisDetailsSteps } from "./utils/formatRawAnalysisDetailsSteps";
import { formatRawAnalysisPendency } from "./utils/formatRawAnalysisPendency";
import { formatRawProductBankRates } from "./utils/formatRawProductBankRates";
import { getNewAnalysisLastRunWithRetry } from "./utils/getNewAnalysisLastRunWithRetry";
import { getStepsWithDelayedRetry } from "./utils/getStepsWithDelayedRetry";

/** Carrega uma conexão para ser aberta na tela de detalhes da conexão */
export const onLoadAnalysisDetails = createAsyncThunk(
  "analysisDetails/onLoadAnalysisDetails",
  async (analysisId: string, { getState }) => {
    let analysisDetailsSteps = null;
    let analysisLastRun = 0;
    const { analysisDetails, products, users } = getState() as {
      analysisDetails: AnalysisDetailsState;
      products: ProductState;
      users: UsersState;
    };
    const { tenantCode, username } = users.currentUser!;

    const analysisDetailsStepsRaw = await getStepsWithDelayedRetry(analysisId);

    if (analysisDetailsStepsRaw) {
      analysisDetailsSteps = formatRawAnalysisDetailsSteps(analysisDetailsStepsRaw);
      analysisLastRun = analysisDetailsStepsRaw.last_run;
    }

    const [analysisDetailsRes, analysisPendenciesRes, aiMessagesRes] = await Promise.all([
      getAnalysisDetails(analysisId),
      getAnalysisPendenciesList(analysisId),
      !analysisDetails.hasLoadedMessages
        ? getAIMessages({ analysis_id: analysisId, tenant_code: tenantCode, username })
        : Promise.resolve(null),
      analysisDetailsStepsRaw && analysisDetailsStepsRaw.latest_ai_run_at < analysisDetailsStepsRaw.last_run
        ? getAITraining(analysisId)
        : Promise.resolve(null),
    ]);

    const analysisDetailsRaw = analysisDetailsRes.data;
    const analysisDetailsFormatted = formatRawAnalysisDetails(analysisDetailsRaw);

    const analysisPendenciesRaw = analysisPendenciesRes.data;
    const analysisPendencies = analysisPendenciesRaw.reduce((acc: ObjectOf<AnalysisPendency>, pendencyRaw) => {
      const pendency = formatRawAnalysisPendency(pendencyRaw);
      acc[pendencyRaw.pendency_id] = pendency;
      return acc;
    }, {});

    const analysisProduct = products.groupProducts[analysisDetailsFormatted.productId];
    const productTargets = analysisProduct.targetUser || [];

    return {
      aiMessageHistory: aiMessagesRes?.data || { messages: [] },
      analysisDetails: analysisDetailsFormatted,
      analysisDetailsSteps,
      analysisLastRun,
      analysisPendencies,
      analysisProduct,
      productTargets,
    };
  }
);

/** Carrega a lista de pendências da conexão aberta */
export const onLoadAnalysisPendencies = createAsyncThunk(
  "analysisDetails/onLoadAnalysisPendencies",
  async (analysisId: string) => {
    const analysisPendenciesRes = await getAnalysisPendenciesList(analysisId);
    const analysisPendenciesRaw = analysisPendenciesRes.data;
    const analysisPendencies = analysisPendenciesRaw.reduce((acc: ObjectOf<AnalysisPendency>, pendencyRaw) => {
      const pendency = formatRawAnalysisPendency(pendencyRaw);
      acc[pendencyRaw.pendency_id] = pendency;
      return acc;
    }, {});
    return analysisPendencies;
  }
);

/** Carrega os bank rates da conexão aberta */
export const onLoadAnalysisBankRates = createAsyncThunk(
  "analysisDetails/onLoadAnalysisBankRates",
  async (productId: string) => {
    const productBankRatesRes = await getProductBankRates(productId);
    const productBankRatesRaw = productBankRatesRes.data;
    const productBankRates = formatRawProductBankRates(productBankRatesRaw);
    return productBankRates;
  }
);

/** Carrega os dados da Pluggy da conexão aberta */
export const onLoadPluggyBookData = createAsyncThunk(
  "analysisDetails/onLoadPluggyBookData",
  async (data: { pluggyAuthorizationsIds: string; selectedPeerDocument: string; whichGraph?: string }) => {
    const pluggyBookData = await getAnalysisPluggyBookData(data.pluggyAuthorizationsIds);
    const peerDocument = data.selectedPeerDocument;
    return { peerDocument, pluggyBookData, whichGraph: data.whichGraph };
  }
);

/** Atualiza os dados de pendência da conexão aberta */
export const onUpdatePendencies = createAsyncThunk(
  "analysisDetails/onUpdatePendencies",
  async (data: { lastRun: number; pendencies: ObjectOf<AnalysisPendency> }, { getState }) => {
    const state = getState() as { analysisDetails: AnalysisDetailsState };
    const { analysisDetails } = state.analysisDetails;
    if (!analysisDetails) {
      throw new Error("Could not get analysisDetails");
    }
    const analysisId = Object.values(data.pendencies)[0].analysisId;
    const pendencyData = Object.values(data.pendencies).map((pendency) => ({
      pendency_id: pendency.pendencyId,
      value: pendency.value,
    }));
    try {
      await updatePendencies(analysisId, pendencyData);
    } catch (error) {
      let errorMessage;
      if (error instanceof Error) errorMessage = error.message;
      else errorMessage = String(error);
      console.log("error: ", errorMessage);
    }
    await getNewAnalysisLastRunWithRetry(analysisDetails, data.lastRun);
  }
);

/** Coloca a conexão aberta para rodar novamente (em caso de alteração de peers) */
export const onRunAnalysis = createAsyncThunk(
  "analysisDetails/onRunAnalysis",
  async (data: { lastRun: number; peers: Array<Omit<PeerDataRaw, "pass_id" | "full_name">> }, { getState }) => {
    const state = getState() as { analysisDetails: AnalysisDetailsState };

    const { analysisDetails, analysisId } = state.analysisDetails;
    if (!analysisDetails || !analysisId) {
      throw new Error("Could not get analysisDetails or analysisId");
    }
    await runOpenedAnalysis(analysisId, data.peers);
    await getNewAnalysisLastRunWithRetry(analysisDetails, data.lastRun);
    return analysisId;
  }
);

/** Atualiza os dados da conexão (resultado e comentários) */
export const onUpdateAnalysisDetails = createAsyncThunk(
  "analysisDetails/onUpdateAnalysisDetails",
  async (analysisDetails: AnalysisDetails) => {
    await updateAnalysisDetails(analysisDetails.analysisId, analysisDetails.result.comments);
    return analysisDetails;
  }
);

export const onUpdateUploadPendencyChoices = createAsyncThunk(
  "analysisDetails/onUpdateUploadPendencyChoices",
  async (data: { choices: AnalysisPendencyUploadChoices; pendencyId: string }, { getState }) => {
    const { analysisDetails } = getState() as { analysisDetails: AnalysisDetailsState };
    const analysisId = analysisDetails.analysisId!;
    const updatedChoices = data.choices;
    const updateChoicesArgs = {
      analysisId,
      pendencyId: data.pendencyId,
      updatedChoices,
      updatedValue: "",
    };

    const dataResponse = await updateChoices(updateChoicesArgs);
    return dataResponse.data;
  }
);

/** Atualiza a URL do documento que foi carregado, pra pendência de upload */
export const onUpdateUploadPendencyDocumentPath = createAsyncThunk(
  "analysisDetails/onUpdateUploadPendencyDocumentPath",
  async (
    data: {
      analysisId: string;
      documentId: number;
      fileName: string;
      fileSize: number;
      isDeleting: boolean;
      passId: string;
      path: string;
      peerType: string;
      pendencyId: string;
      username: string;
    },
    thunk
  ) => {
    try {
      const state = thunk.getState() as { analysisDetails: AnalysisDetailsState };
      const pendency = state.analysisDetails.analysisPendencies[data.pendencyId];
      if (!pendency || !pendency.choices || !("peers" in pendency.choices)) {
        throw new Error("Invalid pendency data");
      }
      const choices = pendency.choices as AnalysisPendencyUploadChoices;
      const updatedPeers = choices.peers.map((peer) => {
        if (peer.pass_id !== data.passId && peer.peer !== data.peerType) {
          return peer;
        }
        const updatedDocuments = peer.documents.map((doc) => {
          if (doc.document_id === data.documentId) {
            const newUpdatesKey = data.isDeleting
              ? doc.updates.filter((update) => update.path !== data.path)
              : [
                  ...doc.updates,
                  {
                    name: data.fileName,
                    pass_id: data.username,
                    path: data.path,
                    size: data.fileSize,
                    timestamp: generateTimestamp(),
                  },
                ];

            return { ...doc, updates: newUpdatesKey };
          }
          return doc;
        });
        return { ...peer, documents: updatedDocuments };
      });
      const isEveryDocumentSent = updatedPeers.every((peer) =>
        peer.documents.every((doc) => doc.updates.some((update) => !!update.path))
      );
      const updatedChoices = { ...choices, peers: updatedPeers };
      const updateChoicesArgs = {
        analysisId: data.analysisId,
        pendencyId: data.pendencyId,
        updatedChoices,
        updatedValue: isEveryDocumentSent ? "finished" : "",
      };
      await updateChoices(updateChoicesArgs);
      return { choices: updatedChoices, pendencyId: data.pendencyId };
    } catch (error) {
      return thunk.rejectWithValue(error);
    }
  }
);

export const onUpdateSignaturePendencyDocumentPath = createAsyncThunk(
  "analysisDetails/onUpdateSignaturePendencyDocumentPath",
  async (
    data: {
      analysisId: string;
      fileName: string;
      fileSize: number;
      first_time_sending_link: boolean;
      isDeleting: boolean;
      passId: string;
      path: string;
      peers: Array<PeerToSignPendency>;
      pendencyId: string;
      rawPath: string;
    },
    thunk
  ) => {
    try {
      const state = thunk.getState() as { analysisDetails: AnalysisDetailsState };
      const pendency = state.analysisDetails.analysisPendencies[data.pendencyId];
      if (!pendency || !pendency.choices || !("peers_to_sign" in pendency.choices)) {
        throw new Error("Invalid pendency data");
      }

      const updatedChoices: AnalysisPendencySignChoices = {
        ...pendency.choices,
        document: {
          path: data.path,
          raw_path: data.rawPath,
          updates: data.isDeleting
            ? []
            : [
                {
                  name: data.fileName,
                  pass_id: data.passId,
                  size: data.fileSize,
                  timestamp: generateTimestamp(),
                },
              ],
        },
        peers: data.peers,
      };

      const updateChoicesArgs = {
        analysisId: data.analysisId,
        pendencyId: data.pendencyId,
        updatedChoices,
        updatedValue: "", // TODO: Passar o updatedValue como "finished" quando todas as assinaturas forem finalizadas
      };
      await updateChoices(updateChoicesArgs);
      return { choices: updatedChoices, pendencyId: data.pendencyId };
    } catch (error) {
      return thunk.rejectWithValue(error);
    }
  }
);

/** Insere uma nova mensagem no chat do modal Liquid AI */
export const onSendMessageToAI = createAsyncThunk(
  "analysisDetails/onSendMessageToAI",
  async (data: { analysisId: string; message: string }) => {
    const createAiMessageReq = await sendMessageToAI(data.analysisId, data.message);
    return createAiMessageReq.data;
  }
);

/** Dá like/dislike/neutral em uma mensagem no chat do modal Liquid AI */
export const onReactToMessage = createAsyncThunk(
  "analysisDetails/onReactToMessage",
  async (data: { action: "dislike" | "like" | "neutral"; messageId: string }, { getState }) => {
    const { analysisDetails } = getState() as { analysisDetails: AnalysisDetailsState };
    const { analysisId } = analysisDetails;
    const reactToMessageReq = await reactToMessage(data.action, analysisId!, data.messageId);
    return reactToMessageReq.data;
  }
);

/** Arquiva/desarquiva a conexão aberta */
export const onArchiveCurrentAnalysis = createAsyncThunk(
  "analysisDetails/onArchiveAnalysis",
  async (archived: boolean, { getState }) => {
    const { analysisDetails } = getState() as { analysisDetails: AnalysisDetailsState };
    const { analysisId } = analysisDetails;
    if (analysisId) await archiveAnalysis([{ [analysisId]: archived }]);
    return archived;
  }
);
