// import MainLoadingScreen from "@common/components/MainLoadingScreen";
import EmailSearch from "@signature/components/EmailSearch";
import ExpiredLink from "@signature/components/ExpiredLink";
import IdrcSigning from "@signature/components/Idrc";
import SignatureLoading from "@signature/components/SignatureLoading";
import SignaturePendencies from "@signature/components/SignaturePendencies";
import SomethingWentWrong from "@signature/components/SomethingWentWrong";
import * as pdfjsLib from "pdfjs-dist";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import store, { useAppDispatch, useAppSelector } from "../../../store";
import ActionFailed from "../components/ActionFailed";
import CloudSigning from "../components/cloud/CloudSigning";
import DocumentSearch from "../components/DocumentSearch";
import DocumentView from "../components/DocumentView";
import LocalSigning from "../components/local/LocalSigning";
import SigningWithoutCloud from "../components/local/SigningWithoutCloud";
import ScheduleSuccess from "../components/ScheduleSuccess";
import SendLiderRequest from "../components/SendLiderRequest";
import SignatureLoadingScreen from "../components/SignatureLoadingScreen";
import SignatureSuccess from "../components/SignatureSuccess";
import SigningProcess from "../components/SigningProcess";
import SigningView from "../components/SigningView";
import {
  onGetingPeerDataByToken,
  onGetingPeerPendency,
  onLoading,
  onSavingToken,
  onSearchContractUrl,
  onSettingDocumentFile,
} from "../signatureSlice";
import { SignatureViews } from "../types/signatureViews";
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

export default function SignatureMain() {
  const dispatch = useAppDispatch();

  const { currentView, loading } = useAppSelector((state) => state.signature);

  const params = useParams();
  const token = params.token || "";

  useEffect(() => {
    const fetchSignatureData = async () => {
      await dispatch(onSavingToken(token));
      await dispatch(onGetingPeerDataByToken(token)).unwrap();
      await dispatch(onSearchContractUrl(token)).unwrap();

      const updatedContract = store.getState().signature.contract;
      await dispatch(onGetingPeerPendency(token)).unwrap();

      const loadingTask = pdfjsLib.getDocument(updatedContract.portalDocumentUrl);
      const pdfFile = await loadingTask.promise;
      await dispatch(onSettingDocumentFile(pdfFile));

      await dispatch(onLoading(false));
    };

    fetchSignatureData();
  }, []);

  const renderViews = () => {
    switch (currentView) {
      case SignatureViews.DOCUMENT_VIEW:
        return <DocumentView />;
      case SignatureViews.CREATE_CERTIFICATE:
        return <SendLiderRequest />;
      case SignatureViews.SCHEDULED:
        return <ScheduleSuccess />;
      case SignatureViews.NOT_SCHEDULED:
        return <ActionFailed action="schedule" />;
      case SignatureViews.START_SIGNING:
        return <SigningProcess />;
      case SignatureViews.DOCUMENT_SEARCH:
        return <DocumentSearch />;
      case SignatureViews.SIGNING_PROCESS:
        return <SigningView />;
      case SignatureViews.WITHOUT_CLOUD_SIGNING:
        return <SigningWithoutCloud />;
      case SignatureViews.LOCAL_SIGNING:
        return <LocalSigning />;
      case SignatureViews.CLOUD_SIGNING:
        return <CloudSigning />;
      case SignatureViews.IDRC_SIGNING:
        return <IdrcSigning />;
      case SignatureViews.SIGNATURE_SUCCESS:
        return <SignatureSuccess />;
      case SignatureViews.SIGNATURE_FAILED:
        return <ActionFailed action="signature" />;
      case SignatureViews.LOADING:
        return <SignatureLoadingScreen />;
      case SignatureViews.EMAIL_SEARCH:
        return <EmailSearch />;
      case SignatureViews.SIGNATURE_PENDENCIES:
        return <SignaturePendencies />;
      case SignatureViews.EXPIRED_LINK:
        return <ExpiredLink />;
      case SignatureViews.SOMETHING_WENT_WRONG:
        return <SomethingWentWrong message="Ops, não encontramos essa página..." />;
      default:
        return <DocumentView />;
    }
  };

  return loading ? <SignatureLoading /> : renderViews();
  // return loading ? <MainLoadingScreen /> : renderViews();
}
