import { Fragment } from "react";
import { useAppSelector } from "../../../../../../store";
import { simulatorVariablesToResultCard } from "../../../../utils/transformSimulatorTypes";
import { useSimulatorResultHandler } from "../../../../utils/useSimulatorResultHandler";
import SimulatorEmptyCard from "../SimulatorEmptyCard";
import SimulatorResultFormsContainer from "../SimulatorResultFormsContainer";
import SimulatorHighLightFilledCard from "./SimulatorHighlightFilledCard";

const INITIAL_CONTENT_LIST = [{ content: null, id: 1 }];

export default function SimulatorResultHighlightsForm() {
  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);

  const simulator = simulatorData!;

  const dashboardHighlights =
    simulator.dashboard.highlight.length > 0
      ? simulator.dashboard.highlight.map((highlight) => ({
          content: simulatorVariablesToResultCard(highlight, highlight.index),
          id: highlight.index,
        }))
      : INITIAL_CONTENT_LIST;

  const { addNewComponent, removeComponent, updateComponent } = useSimulatorResultHandler({
    type: "highlight",
  });

  return (
    <SimulatorResultFormsContainer
      addNewComponent={addNewComponent}
      subtitle="Defina variáveis para exibir como destaques no Dashboard"
      title="Destaques"
    >
      {dashboardHighlights.map((highlight, index) => (
        <Fragment key={`card-${highlight.id}`}>
          {highlight.content?.name ? (
            <SimulatorHighLightFilledCard
              handleDeleteClick={removeComponent}
              index={highlight.content.index}
              key={highlight.content.index}
              length={dashboardHighlights.length}
              selectedVariable={highlight}
              wrapper={{
                border: "1px solid rgba(212, 215, 220, 1)",
                borderRadius: "12px",
                maxWidth: "calc(795px - 24px)",
                padding: "12px 12px",
                width: "100%",
              }}
            />
          ) : (
            <SimulatorEmptyCard
              handleChange={updateComponent}
              index={highlight.content?.index || index}
              selectedVariable={highlight}
              wrapper={{ maxWidth: "795px", width: "100%" }}
            />
          )}
        </Fragment>
      ))}
    </SimulatorResultFormsContainer>
  );
}
