import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { LqdShieldIcon } from "@/liquid-components/src/icons";
import RightArrow from "@/liquid-components/src/icons/RightArrow";
import { dialogCalled } from "@common/commonSlice";
import { Box, Divider, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { onChangingView } from "../../signatureSlice";
import { SignatureViews } from "../../types/signatureViews";
import RegistryNavigationBar from "../RegistryNavigationBar";
import LqdSecureEnvironment from "../SecureEnvironment";
import SignatureHeader from "../SignatureHeader";

export default function CloudSigning() {
  const dispatch = useAppDispatch();
  const { cloudProviders, token } = useAppSelector((state) => state.signature);

  const onConfirm = (url: string) => {
    localStorage.setItem("peer_token", token);
    window.location.href = url;
  };

  return (
    <Box
      sx={{
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <SignatureHeader />
      <RegistryNavigationBar
        centerContent={{
          text: "Etapa 2 de 2",
        }}
        previusPage={SignatureViews.DOCUMENT_SEARCH}
        step={SignatureViews.CLOUD_SIGNING}
      />
      <Box
        sx={{
          alignItems: "left",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          height: "auto",
          justifyContent: "center",
          margin: "60px auto 0",
          padding: "24px",
          width: {
            sm: "400px",
            xs: "90%",
          },
        }}
      >
        <Stack sx={{ alignSelf: "center" }}>
          <LqdShieldIcon />
        </Stack>
        <LqdTypography
          color="rgba(33, 36, 42, 1)"
          sx={{ mt: 2, textAlign: "left" }}
          textAlign="left"
          textstyle="h3Headline"
        >
          Certificado Digital e-CPF
        </LqdTypography>
        <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ mt: 1, textAlign: "left" }}>
          Você precisa de um certificado para assinar este documento. Escolha algum dos provedores abaixo para
          continuar:
        </LqdTypography>

        <Stack alignSelf="center" flexDirection="row" flexWrap="wrap" gap={2} justifyContent="center" mb={3} mt={5}>
          {cloudProviders.map((provider) => (
            <Stack
              alignItems="center"
              direction="column"
              gap={1}
              justifyContent="center"
              key={provider.service_info.provider}
              onClick={() =>
                dispatch(
                  dialogCalled({
                    actions: [{ title: "Voltar" }, { onClick: () => onConfirm(provider.auth_url), title: "Sim" }],
                    body: `Você está prestes a entrar na página do provedor 
                     para realizar a assinatura. Deseja continuar?`,
                    title: "Atenção",
                    type: "default",
                  })
                )
              }
              sx={{
                "&:hover": { cursor: "pointer" },
                border: "1px solid rgba(212, 215, 220, 1)",
                borderRadius: 2,
                mb: 2,
                width: "160px",
              }}
            >
              <Box
                alt="provider service info badge"
                component="img"
                src={provider.service_info.badge_url}
                sx={{
                  borderRadius: 1.5,
                  height: "100px",
                  padding: {
                    sm: "8px 16px",
                    xs: "8px",
                  },
                  width: "90%",
                }}
              />

              <Divider sx={{ borderColor: "rgba(212, 215, 220, 1)", width: "100%" }} />
              <LqdTypography color="rgba(33, 36, 42, 1)" sx={{ p: 1, textAlign: "center" }}>
                {`Assinar com ${provider.service_info.service_name}`}
              </LqdTypography>
            </Stack>
          ))}
        </Stack>

        <LqdButton
          color="primary"
          endIcon={<RightArrow />}
          onClick={() => dispatch(onChangingView(SignatureViews.WITHOUT_CLOUD_SIGNING))}
          sx={{ mt: 2, width: "100%" }}
          type="ghostPrimary"
          variant="contained"
        >
          Assinar com Certificado Local
        </LqdButton>
        <LqdButton
          color="primary"
          endIcon={<RightArrow />}
          onClick={() => dispatch(onChangingView(SignatureViews.IDRC_SIGNING))}
          sx={{ mt: 2, width: "100%" }}
          type="ghostPrimary"
          variant="contained"
        >
          Assinar com idRC
        </LqdButton>
        <LqdButton
          color="primary"
          endIcon={<RightArrow />}
          onClick={() => dispatch(onChangingView(SignatureViews.CREATE_CERTIFICATE))}
          sx={{ width: "100%" }}
          type="ghostPrimary"
          variant="contained"
        >
          Quero fazer um novo Certificado Digital
        </LqdButton>
        <LqdSecureEnvironment />
      </Box>
    </Box>
  );
}
