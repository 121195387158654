import { LqdButton, LqdTypography } from "@/liquid-components/src";
import { Box, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { createTenantModalStepNext, editTenantModalStepGoTo, editTenantName } from "../../../adminSlice";

const CHAR_LIMIT = 25;

export default function TenantFormStep1() {
  const dispatch = useAppDispatch();
  const { createTenant, tenantToEdit } = useAppSelector((state) => state.admin);

  const showNameLengthAlert = tenantToEdit ? tenantToEdit.contact.name.length > 25 : false;

  const handleNameError = () => {
    switch (true) {
      case showNameLengthAlert:
        return `Você ultrapassou o limite de ${CHAR_LIMIT} caracteres. Abrevie para continuar.`;
      default:
        return "";
    }
  };

  const handleSubmit = () => {
    dispatch(createTenant ? createTenantModalStepNext() : editTenantModalStepGoTo(4));
  };

  return (
    <Box sx={{ height: "240px", width: "100vw" }}>
      <LqdTypography sx={{ color: "rgba(33, 36, 42, 1)" }} textstyle="h5Headline">
        Nome da empresa
      </LqdTypography>
      <TextField
        autoFocus
        error={showNameLengthAlert}
        inputProps={{
          sx: {
            color: "rgba(33, 36, 42, 1)",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "28px",
            marginBottom: "12px",
            padding: 0,
          },
        }}
        onChange={(event) => {
          dispatch(editTenantName({ tenantName: event.target.value }));
        }}
        onKeyUp={(key) => key.key === "Enter" && handleSubmit()}
        placeholder="Digite"
        sx={{
          ".MuiInputBase-root": { my: 1.5, pl: 2 },
          width: "100%",
        }}
        value={tenantToEdit ? tenantToEdit.contact.name : ""}
        variant="standard"
      />
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
        <LqdTypography sx={{ color: "rgba(246, 61, 94, 1)" }} textstyle="p2Paragraph">
          {handleNameError()}
        </LqdTypography>
        <LqdTypography
          sx={{
            color: showNameLengthAlert ? "rgba(246, 61, 94, 1)" : "rgba(33, 36, 42, 1)",
            display: "block",
            textAlign: "right",
          }}
          textstyle="p2Paragraph"
        >
          {`${tenantToEdit?.contact.name?.length}/${CHAR_LIMIT} caracteres`}
        </LqdTypography>
      </Box>
      <Box sx={{ mt: 5, textAlign: "right" }}>
        <LqdButton
          disabled={!(tenantToEdit && tenantToEdit?.contact?.name?.length > 3 && !showNameLengthAlert)}
          onClick={handleSubmit}
        >
          Avançar
        </LqdButton>
      </Box>
    </Box>
  );
}
