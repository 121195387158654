import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import Draggable from "gsap/Draggable";
import { LqdIconButton, LqdTooltip, LqdTrashIcon, LqdDragAndDropIcon, LqdTypography } from "@/liquid-components/src";
import { formatTrustpadDashboardCardValue } from "@analysis/utils/formatTrustpadDashboardCardValue";
import { truncateString } from "@common/utils/truncateString";
import { Box, Stack, SxProps } from "@mui/material";
import { SimulatorVariable } from "../../../../types/SimulatorVariable";
import { setDashboardHighlights } from "@simulatorBuilder/simulatorBuilderSlice";
import { ToastNotification } from "@common/types/ToastNotification";
import { toastCalled } from "@common/commonSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../store";

type SimulatorResultFilledCard = {
  handleDeleteClick: (index: number) => void;
  index: number;
  length: number;
  selectedVariable: { content: SimulatorVariable | null; id: number };
  wrapper: SxProps;
};

gsap.registerPlugin(Draggable);

export default function SimulatorHighLightFilledCard(props: SimulatorResultFilledCard) {
  const { handleDeleteClick, index, length, selectedVariable, wrapper } = props;
  const { simulatorData } = useAppSelector((state) => state.simulatorBuilder);

  const highlights = simulatorData?.dashboard.highlight;

  const dispatch = useAppDispatch();

  const highlightRef = useRef<HTMLDivElement>(null);

  const sendErrorNotification = () => {
    const notification: ToastNotification = {
      message: "Não existem cards para serem movidos.",
      type: "error",
    };

    dispatch(toastCalled(notification));
  };

  const handleReorder = (oldIndex: number, newIndex: number) => {
    if (!highlights?.length) {
      sendErrorNotification();

      return;
    }

    const copyArray = [...highlights];

    if (oldIndex === newIndex) return;

    const movedItem = copyArray.find((item) => item.index === oldIndex);

    if (!movedItem) {
      const notification: ToastNotification = {
        message: "Item a ser movido não encontrado.",
        type: "error",
      };

      dispatch(toastCalled(notification));
      return;
    }

    copyArray.splice(oldIndex - 1, 1);
    copyArray.splice(newIndex - 1, 0, movedItem);

    const reorderedArray = copyArray.map((item, index) => ({
      ...item,
      index: index + 1,
    }));

    dispatch(setDashboardHighlights(reorderedArray));
  };

  useEffect(() => {
    const el = highlightRef.current;
    const cardHeight = el?.offsetHeight || 0;

    Draggable.create(el, {
      onDrag: function () {
        const elements = document.querySelectorAll('[data-draggable="true"]');
        const dragBounds = el!.getBoundingClientRect();
        const dragCenterY = dragBounds.top + dragBounds.height / 2;

        elements.forEach((item) => {
          if (item.innerHTML === el?.innerHTML) return;

          const bounds = item.getBoundingClientRect();
          const centerY = bounds.top + bounds.height / 2;

          if (dragCenterY < centerY && dragCenterY > bounds.top) {
            gsap.to(item, {
              duration: 0.3,
              ease: "power2.out",
              y: cardHeight / 3,
            });
          } else if (dragCenterY > centerY && dragCenterY < bounds.bottom) {
            gsap.to(item, {
              duration: 0.3,
              ease: "power2.out",
              y: -cardHeight / 3,
            });
          } else {
            gsap.to(item, {
              duration: 0.3,
              ease: "power2.out",
              y: 0,
            });
          }
        });
      },
      onDragEnd: function () {
        const newIndex = Math.round(this.y / el!.offsetHeight);
        const positiveIndex = Math.abs(newIndex);
        const maxIndex = positiveIndex > length ? length : positiveIndex;
        const calcNewIndex =
          maxIndex > selectedVariable.id ? maxIndex - selectedVariable.id + 1 : selectedVariable.id - maxIndex + 1;

        handleReorder(selectedVariable.id, calcNewIndex);

        const elements = document.querySelectorAll('[data-draggable="true"]');

        elements.forEach((item) => {
          gsap.to(item, {
            duration: 0.3,
            ease: "power2.out",
            y: 0,
          });
        });

        gsap.to(el, {
          duration: 0.5,
          ease: "power2.out",
          y: 0,
        });
      },
      onPress() {
        gsap.to(el, { borderLeft: "3px solid gray", duration: 0.2, rotation: -2.5, x: 0 });
      },
      onRelease() {
        gsap.to(el, { borderLeft: "1px solid rgba(212, 215, 220, 1)", duration: 0.2, rotation: 0, x: 0 });
      },
      type: "y",
    });
  }, [index, handleReorder]);

  return (
    <Stack
      data-draggable="true"
      direction="row"
      ref={highlightRef}
      spacing="auto"
      sx={{ ...wrapper, background: "rgba(249, 249, 250, 1)" }}
    >
      <Stack alignItems="center" direction="row" spacing={1}>
        <LqdDragAndDropIcon size={20} />
        <Stack direction="column">
          <Stack direction="row" spacing={0.5}>
            <LqdTooltip
              hidetooltip={selectedVariable!.content!.label.length > 35 ? "false" : "true"}
              placement="top"
              titlebody={selectedVariable!.content!.label}
            >
              <LqdTypography color="rgba(75, 75, 75, 1)" sx={{ overflowWrap: "break-word" }} textstyle="c1Caption">
                {truncateString(selectedVariable!.content!.label, 35)}
              </LqdTypography>
            </LqdTooltip>
            <LqdTooltip
              hidetooltip={selectedVariable!.content!.name.length > 35 ? "false" : "true"}
              placement="top"
              titlebody={selectedVariable!.content!.name}
            >
              <LqdTypography color="rgba(127, 135, 152, 1)" sx={{ overflowWrap: "break-word" }} textstyle="c1Caption">
                {truncateString(selectedVariable!.content!.name, 35)}
              </LqdTypography>
            </LqdTooltip>
          </Stack>
          <LqdTypography color="rgba(33, 36, 42, 1)" textstyle="buttonXL">
            {selectedVariable.content?.value
              ? formatTrustpadDashboardCardValue(
                  selectedVariable.content.value,
                  selectedVariable.content.type!,
                  selectedVariable.content.label
                )
              : "Valor não definido (dinâmico)"}
          </LqdTypography>
        </Stack>
      </Stack>
      <Box sx={{ alignSelf: "flex-end", display: "inline-flex" }}>
        <LqdIconButton onClick={() => handleDeleteClick(index)} type="ghostIcon">
          <LqdTrashIcon color="rgba(127, 135, 152, 1)" size={20} />
        </LqdIconButton>
      </Box>
    </Stack>
  );
}
