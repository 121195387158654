import { LqdEditIcon, LqdIconButton, LqdTable, LqdToggle } from "@/liquid-components/src";
import SettingsRouteHeader from "@common/components/SettingsRouteHeader";
import { dateFormatter } from "@common/utils/dataFormatter";
import { Box, Stack } from "@mui/material";
import { handleGlobalProviders } from "@products/utils/handleGlobalProviders";
import { AdminPagePanels } from "@superadmin/types/AdminPagePanels";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { dialogCalled, dialogLoaded, toastCalled } from "../../../common/commonSlice";
import FullscreenDialog from "../../../common/components/FullscreenDialog";
import { ToastNotification } from "../../../common/types/ToastNotification";
import { useErrorHandler } from "../../../common/utils/useErrorHandler";
import {
  createTenanModalClosed,
  createTenantModalOpened,
  createTenantModalStepPrev,
  editTenantModalClosed,
  editTenantModalOpened,
  editTenantModalStepPrev,
  onChangePanel,
  onSelectTenant,
} from "../../adminSlice";
import { onLoadTenants, onUpdateTenant } from "../../adminSliceThunks";
import { TenantResponse } from "../../types/TenantResponse";
import CreateTenantForm from "./CreateTenantForm";
import EditTenantForm from "./EditTenantForm";

export default function TenantConfig() {
  const dispatch = useAppDispatch();
  const handleLiquidErrors = useErrorHandler();

  const { createTenant, createTenantModalStep, editTenantModalStep, providers, segments, tenants, tenantToEdit } =
    useAppSelector((state) => state.admin);

  const initialCheckedProviders = Object.values(providers)
    .filter((provider) => provider.global)
    .map((provider) => provider.providerId);

  const [checkedProviders, setCheckedProviders] = useState<Array<string>>(initialCheckedProviders);
  const [checkedSegments, setCheckedSegments] = useState<Array<string>>([]);

  const checkedProvidersChanged =
    JSON.stringify([...(tenantToEdit?.providers || [])].sort()) !== JSON.stringify([...checkedProviders].sort());
  const checkedSegmentsChanged =
    JSON.stringify([...(tenantToEdit?.segments || [])].sort()) !== JSON.stringify([...checkedSegments].sort());

  const anythingChanged = checkedProvidersChanged || checkedSegmentsChanged;

  // Seta os valores do form (nome, segmentos e providers) toda vez que o tenant editado mudar
  useEffect(() => {
    if (tenantToEdit) {
      setCheckedProviders(tenantToEdit.providers);
      setCheckedSegments(tenantToEdit.segments);
    } else {
      setCheckedProviders([]);
      setCheckedSegments([]);
    }
  }, [tenantToEdit, createTenant]);

  const handleCloseCreateClick = () => {
    dispatch(createTenanModalClosed());
  };

  const handleCloseEditClick = () => {
    dispatch(editTenantModalClosed());
  };

  const handleTenantStatusChange = (tenant: TenantResponse) => {
    if (!tenant.isBlocked) {
      dispatch(
        dialogCalled({
          actions: [{ title: "Voltar" }, { onClick: () => confirmTenantStatusChange(tenant), title: "Desativar" }],
          body: "Ao desativá-la, a empresa não poderá criar ou editar conexões. Deseja prosseguir?",
          title: "Desativar Empresa",
          type: "default",
        })
      );
    } else {
      confirmTenantStatusChange(tenant);
    }
  };

  const confirmTenantStatusChange = async (tenant: TenantResponse) => {
    dispatch(dialogLoaded(true));

    const isBlocked = tenant.isBlocked;

    try {
      const requestBody = {
        contact: tenant.contact,
        is_blocked: !isBlocked,
        providers: tenant.providers,
        segments: tenant.segments,
      };

      await dispatch(onUpdateTenant({ tenantCode: tenant.code, tenantInfo: requestBody }));
      await dispatch(onLoadTenants());

      const notification: ToastNotification = {
        message: `A empresa foi ${!isBlocked ? "desativada" : "ativada"} com sucesso.`,
        type: "success",
      };

      dispatch(toastCalled(notification));
      dispatch(dialogCalled(null));
    } catch (error) {
      handleLiquidErrors(error);
    } finally {
      dialogLoaded(false);
    }
  };

  const handleSelectTenant = (tenant: TenantResponse) => {
    dispatch(onSelectTenant(tenant.code));
    dispatch(onChangePanel(AdminPagePanels.TENANT_CONFIG));
  };

  const tableContent = Object.values(tenants).map((tenant) => {
    const uniqueProviderList = handleGlobalProviders(Object.values(providers), tenant.providers);

    const firstThreeProviders = uniqueProviderList.slice(0, 3);
    const extraProvidersLength = uniqueProviderList.length - firstThreeProviders.length;

    const firstThreeSegments = tenant.segments.slice(0, 3);
    const extraSegmentsLength = tenant.segments.length - firstThreeSegments.length;

    return [
      {
        primaryValue: tenant.contact.name,
        tertiaryValue: {
          method: () => dispatch(editTenantModalOpened({ step: 1, tenant })),
          text: "Editar",
        },
      },
      {
        primaryValue: tenant.segments.length > 9 ? tenant.segments.length : "0" + tenant.segments.length,
        tertiaryValue: {
          method: () => dispatch(editTenantModalOpened({ step: 2, tenant })),
          text: "Editar",
        },
        tooltip: {
          body:
            firstThreeSegments.length > 0 ? (
              <>
                {firstThreeSegments.map((segmentId, index) => (
                  <Box key={`${segmentId}-${index}`}>{segments[segmentId].name}</Box>
                ))}
                {extraSegmentsLength > 0 ? <Box>{`+ ${extraSegmentsLength} outros`}</Box> : null}
              </>
            ) : (
              "Nenhum segmento"
            ),
          header: "Segmentos",
        },
      },
      {
        primaryValue: uniqueProviderList.length > 9 ? uniqueProviderList.length : "0" + uniqueProviderList.length,
        tertiaryValue: {
          method: () => dispatch(editTenantModalOpened({ step: 3, tenant })),
          text: "Editar",
        },
        tooltip: {
          body:
            firstThreeProviders.length > 0 ? (
              <>
                {firstThreeProviders.map((providerId, index) => (
                  <Box key={`${providerId}-${index}`}>{providers[providerId].title}</Box>
                ))}
                {extraProvidersLength > 0 ? <Box>{`+ ${extraProvidersLength} outros`}</Box> : null}
              </>
            ) : (
              "Nenhum provider"
            ),
          header: "Providers",
        },
      },
      {
        primaryValue: dateFormatter(tenant.createdAt).fullDateText,
        tertiaryValue: dateFormatter(tenant.createdAt).fullTime,
      },
      {
        primaryValue: (
          <LqdToggle active={!tenant.isBlocked} onChange={() => handleTenantStatusChange(tenant)} renderText />
        ),
      },
      {
        primaryValue: (
          <LqdIconButton onClick={() => handleSelectTenant(tenant)} sx={{ ml: -3, mt: -1 }} type="ghostIcon">
            <LqdEditIcon />
          </LqdIconButton>
        ),
      },
    ];
  });

  return (
    <>
      <SettingsRouteHeader
        buttonMethod={() => dispatch(createTenantModalOpened())}
        buttonTitle="Nova Empresa"
        sx={{ mt: 5 }}
        title="Empresas"
        tooltipBody="Crie uma empresa, possibilitando que ela cadastre usuários e utilize os serviços da Liquid."
        tooltipHeader="Adicionar uma empresa"
      />
      <Stack sx={{ alignItems: "center", justifyContent: "center", mt: 2 }}>
        <Stack sx={{ width: "87.5%" }}>
          <LqdTable<TenantResponse>
            height="450px"
            tableContent={tableContent}
            tableHeader={["Empresa", "Segmentos", "Providers", "Criação", "Status", ""]}
          />
        </Stack>
      </Stack>
      <FullscreenDialog
        action="create"
        currentStep={createTenantModalStep}
        handleGoBack={() => dispatch(createTenantModalStepPrev())}
        onClose={handleCloseCreateClick}
        open={createTenant}
        title="Adicionar Empresa"
      >
        <CreateTenantForm
          checkedProviders={checkedProviders}
          checkedSegments={checkedSegments}
          name={tenantToEdit ? tenantToEdit.contact.name : ""}
          onClose={handleCloseCreateClick}
          setCheckedProviders={setCheckedProviders}
          setCheckedSegments={setCheckedSegments}
        />
      </FullscreenDialog>
      <FullscreenDialog
        action="edit"
        currentStep={editTenantModalStep}
        existingChanges={anythingChanged}
        handleGoBack={() => dispatch(editTenantModalStepPrev())}
        onClose={handleCloseEditClick}
        open={Boolean(tenantToEdit)}
        title="Edição de Empresa"
      >
        {tenantToEdit ? (
          <EditTenantForm
            checkedProviders={checkedProviders}
            checkedSegments={checkedSegments}
            onCloseDialog={handleCloseEditClick}
            setCheckedProviders={setCheckedProviders}
            setCheckedSegments={setCheckedSegments}
            tenant={tenantToEdit}
          />
        ) : null}
      </FullscreenDialog>
    </>
  );
}
